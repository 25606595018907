import { DOC_ID_FRONT_PAGE } from 'utils/sanity/sanityTypes';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import App from 'components/App';
import Footer from 'components/layout/Footer';
import FrontPage from 'components/general_pages/FrontPage';
import Head from 'next/head';
import InfoBar from 'components/info/InfoBar';
import React from 'react';
import config from 'config';
import fetchDocumentSettings from 'utils/sanity/fetchDocumentSettings';
import groq from 'groq';
import sanityClient from 'utils/sanity/sanityClient';

interface IndexProps {
  doc: any;
  docs: any;
  featuredBlog: any;
  settings: any;
  page: any;
}
/**
 * The front page of the whole application.
 *
 * @param {IndexProps} props
 * @return {JSX.Element}
 */
const Index = ({
  doc,
  docs,
  featuredBlog,
  settings,
}: IndexProps): JSX.Element => {
  // TODO: Meta information from active profile
  return <>
    <Head>
      <title>Block</title>
    </Head>
    <App>
      <InfoBar infobar={settings.infobar} />
      <FrontPage doc={doc} docs={docs} phone={settings.phone} featuredBlog={featuredBlog} />
      <Footer footer={settings.footer} phone={settings.phone} />
    </App>
  </>;
};

// TODO: Make SRI multi tenant system based on Vercel example

// export const getServerSideProps = async () => {
//   const pageBloc = new PageBloc({
//     pageId: 'block',
//   }, (error) => {
//     console.error(error);
//   });

//   const data = await pageBloc.get();

//   return {
//     props: {
//       page: {
//         elements: data?.elements ?? '[]',
//         keywords: data?.keywords ?? '',
//         title: data?.title ?? '',
//         desc: data?.desc ?? '',
//       },
//     },
//     // redirect: {
//     //   destination: NavigationPath.MENU,
//     //   permanent: false,
//     // },
//   };
// };

/**
   *
   * @param {{ locale: string }} param0
    * @return {any}
    */
/* export async function getStaticProps({
  locale,
}) {
  const settings = await fetchDocumentSettings();
  return {
    props: {
      ...await serverSideTranslations(locale, ['common',]),
      ...settings,
    },
  };
}
 */

export const getStaticProps = async ({
  locale,
}) => {
  const doc = await sanityClient.fetch(groq`
  *[_id == $id][0]
  `, {
    id: DOC_ID_FRONT_PAGE,
  });
  const docs = await sanityClient.fetch(groq`
  *[_type == "service-doc"]
`);
  const featuredBlog = await sanityClient.fetch(groq`
  *[featured == true && publishedAt < now()] | order(publishedAt desc)[0]
  `);
  const settings = await fetchDocumentSettings();

  return {
    props: {
      doc,
      docs,
      featuredBlog,
      ...settings,
      ...await serverSideTranslations(locale, ['common',]),
    },
    revalidate: config.environment.target === 'production' ?
      60 * 10 : 1,
  };
};
export default Index;
